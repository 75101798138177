import {FiMonitor, FiMoon, FiSun} from 'react-icons/fi';
import {useCallback, useContext, useMemo} from 'react';

import {THEME} from '../configs/enums.js';
import ThemeContext from '../context/ThemeContext';

const THEME_ICON = {
    [THEME.AUTO]: <FiMonitor />,
    [THEME.LIGHT]: <FiSun />,
    [THEME.DARK]: <FiMoon />
};
const THEME_NAME = {
    [THEME.AUTO]: '自动',
    [THEME.LIGHT]: '浅色',
    [THEME.DARK]: '深色'
};

export default function useThemeMenu() {
    const [theme, setTheme] = useContext(ThemeContext);

    const selectedKeys = useMemo(() => [`theme-${theme}`], [theme]);

    const onClick = useCallback(key => setTheme(key.replace('theme-', '')), [setTheme]);

    const item = useMemo(
        () => ({
            key: 'theme',
            icon: THEME_ICON[theme],
            selectable: false,
            children: [THEME.AUTO, THEME.LIGHT, THEME.DARK].map(t => ({
                label: THEME_NAME[t],
                icon: THEME_ICON[t],
                key: `theme-${t}`
            }))
        }),
        [theme]
    );
    return {item, selectedKeys, onClick};
}
