import {
    CommentOutlined,
    ContactsOutlined,
    DropboxOutlined,
    FundOutlined,
    GiftOutlined,
    IdcardOutlined,
    NotificationOutlined,
    ProfileOutlined,
    SettingOutlined,
    SolutionOutlined,
    TeamOutlined,
    VideoCameraOutlined
} from '@ant-design/icons';

import pages from './pages.jsx';

const menus = [
    {
        id: 'statistic',
        label: '数据统计',
        icon: <FundOutlined />
    },
    {
        id: 'danmaku-live',
        label: '实时弹幕',
        icon: <NotificationOutlined />
    },
    {
        id: 'gift-live',
        label: '实时礼物',
        icon: <DropboxOutlined />
    },
    {
        id: 'guard-history',
        label: '上舰记录',
        icon: <IdcardOutlined />
    },
    {
        id: 'guard-management',
        label: '舰长管理',
        icon: <ContactsOutlined />
    },
    {
        id: 'account-management',
        label: '账号管理',
        icon: <SolutionOutlined />
    },
    {
        id: 'fan-management',
        label: '粉丝列表',
        icon: <TeamOutlined />
    },
    {
        id: 'video-list',
        label: '录播列表',
        icon: <VideoCameraOutlined />
    },
    {
        id: 'danmaku-enroll',
        label: '弹幕报名',
        icon: <CommentOutlined />
    },
    {
        id: 'gift-gallery',
        label: '礼物图片',
        icon: <GiftOutlined />
    },
    {
        id: 'user-management',
        label: '用户管理',
        icon: <ProfileOutlined />
    },
    {
        id: 'system-admin',
        label: '系统管理',
        icon: <SettingOutlined />
    }
]
    .filter(item => pages.findIndex(page => page.id === item.id) >= 0)
    .map(item => ({
        ...item,
        path: pages.find(page => page.id === item.id).path
    }));

export default menus;
