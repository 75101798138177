import {Navigate, RouterProvider, createBrowserRouter} from 'react-router-dom';

import AppLayout from '../layout/AppLayout.jsx';
import SimpleLayout from '../layout/SimpleLayout.jsx';
import {lazy} from 'react';
import pages from './pages.jsx';

function createRoutesFromPages(pages) {
    return pages.map(page => {
        const Component = lazy(page.component);
        return {
            id: page.id,
            path: page.path.replace(/^\//, ''),
            element: <Component />,
            loader: async () => {
                const {layoutProps} = await page.component();
                return {layoutProps};
            }
        };
    });
}

const router = createBrowserRouter([
    {
        id: 'simple-layout',
        path: '/',
        element: <SimpleLayout />,
        children: createRoutesFromPages(pages.filter(page => page.public))
    },
    {
        id: 'app-layout',
        path: '/',
        element: <AppLayout />,
        children: createRoutesFromPages(pages.filter(page => !page.public))
    },
    {
        path: '*',
        element: <Navigate to="/" />
    }
]);

export default function Router() {
    return <RouterProvider router={router} />;
}
