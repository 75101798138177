import preval from 'preval.macro';

export const TITLE = process.env.REACT_APP_TITLE;
export const LOGO = process.env.REACT_APP_LOGO;
export const ANCHOR_NAME = process.env.REACT_APP_ANCHOR_NAME || '未知';
export const LEGAL_INFO = process.env.REACT_APP_LEGAL_INFO;
export const COPYRIGHT = `© 2021-${new Date().getFullYear()}`;

export const ROOM_ID = preval`module.exports = process.env.ROOM_ID;`;
export const MID = preval`module.exports = process.env.MID;`;
export const VERSION = preval`module.exports = require('../../package.json').version;`;
export const BUILD_TIME = preval`module.exports = Date.now();`;
export const INDEX_PAGE = preval`module.exports = process.env.INDEX_PAGE || 'login';`;
export const EXCLUDE_PAGES = preval`
    module.exports = process.env.EXCLUDE_PAGES
        ? process.env.EXCLUDE_PAGES.split(',')
        : [];
`;
