import Header from './Header';
import HeaderMenu from './HeaderMenu';
import Logo from './Logo';
import styled from 'styled-components';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 142px 1fr;
    grid-column-gap: 10px;

    > .menu-trigger {
        text-align: left;
        padding-left: ${props => props.theme.padding}px;
    }
`;

export default function AppHeader({menuTrigger, showLogo, style, className}) {
    return (
        <Header className={className} style={style}>
            <Container>
                <div className="menu-trigger">{menuTrigger}</div>
                <div className="logo">{showLogo ? <Logo /> : null}</div>
                <HeaderMenu hideUsername={showLogo} />
            </Container>
        </Header>
    );
}
