import {createGlobalStyle, keyframes} from 'styled-components';

const spinner = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const ProgressStyle = createGlobalStyle`
    #nprogress {
        --z-index: ${props => props.theme.zIndexPopupBase + 1000};

        pointer-events: none;
        color: ${props => props.theme.colorPrimary};

        .bar {
            background: currentColor;

            position: fixed;
            z-index: var(--z-index);
            top: 0;
            left: 0;

            width: 100%;
            height: 2px;
        }

        .peg {
            display: block;
            position: absolute;
            right: 0px;
            width: 100px;
            height: 100%;
            box-shadow: 0 0 10px currentColor, 0 0 5px currentColor;
            opacity: 1;
            transform: rotate(3deg) translate(0px, -4px);
        }

        .spinner {
            display: block;
            position: fixed;
            z-index: var(--z-index);
            top: 15px;
            right: 15px;

            .spinner-icon {
                width: 18px;
                height: 18px;
                box-sizing: border-box;

                border: solid 2px transparent;
                border-top-color: currentColor;
                border-left-color: currentColor;
                border-radius: 50%;

                animation: ${spinner} 400ms linear infinite;
            }
        }
    }
`;

export default ProgressStyle;
