import {useCallback, useMemo} from 'react';

import {Menu} from 'antd';
import styled from 'styled-components';
import useThemeMenu from '../hooks/useThemeMenu';
import useUserMenu from '../hooks/useUserMenu';

const StyledMenu = styled(Menu)`
    flex-direction: row-reverse;
    color: inherit;
    background-color: inherit;

    .ant-menu-title-content:empty {
        display: none;
    }

    .ant-menu-submenu-selected {
        color: inherit !important;

        &:not(.ant-menu-submenu-open)::after {
            opacity: 0 !important;
        }

        > .ant-menu-submenu-title {
            color: inherit !important;
        }
    }
`;

export default function HeaderMenu({hideUsername}) {
    const {item: userMenu, selectedKeys: selectedUserKeys} = useUserMenu(hideUsername);
    const {item: themeMenu, selectedKeys: selectedThemeKeys, onClick: onClickTheme} = useThemeMenu();

    const items = useMemo(() => [...(userMenu ? [userMenu] : []), themeMenu], [themeMenu, userMenu]);
    const selectedKeys = useMemo(
        () => [...selectedUserKeys, ...selectedThemeKeys],
        [selectedThemeKeys, selectedUserKeys]
    );
    const onClick = useCallback(
        ({key, keyPath}) => {
            const topKey = keyPath[keyPath.length - 1];
            switch (topKey) {
                case 'theme':
                    return onClickTheme(key);
                default:
                    return;
            }
        },
        [onClickTheme]
    );

    return <StyledMenu mode="horizontal" items={items} selectedKeys={selectedKeys} onClick={onClick} />;
}
