import {Layout} from 'antd';
import styled from 'styled-components';

const StyledContent = styled(Layout.Content)`
    margin-top: ${props => props.theme.Layout.layoutHeaderHeight}px;
`;

export default function Content({className, style, children}) {
    return (
        <StyledContent className={className} style={style}>
            {children}
        </StyledContent>
    );
}
