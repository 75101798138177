import 'dayjs/locale/zh-cn.js';

import {_adapters} from 'chart.js';
import advancedFormat from 'dayjs/plugin/advancedFormat.js';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration.js';
import isoWeek from 'dayjs/plugin/isoWeek.js';
import localData from 'dayjs/plugin/localeData.js';
import localizedFormat from 'dayjs/plugin/localizedFormat.js';
import quarterOfYear from 'dayjs/plugin/quarterOfYear.js';
import relativeTime from 'dayjs/plugin/relativeTime.js';
import weekday from 'dayjs/plugin/weekday.js';

dayjs.locale('zh-cn');

dayjs.extend(advancedFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(isoWeek);
dayjs.extend(duration);
dayjs.extend(weekday);
dayjs.extend(localData);

const FORMATS = {
    datetime: 'MMM D, YYYY, h:mm:ss a',
    millisecond: 'h:mm:ss.SSS a',
    second: 'h:mm:ss a',
    minute: 'h:mm a',
    hour: 'hA',
    day: 'MMM D',
    week: 'll',
    month: 'MMM YYYY',
    quarter: '[Q]Q - YYYY',
    year: 'YYYY'
};

_adapters._date.override({
    _id: 'dayjs',
    formats: () => FORMATS,
    parse: function (value, format) {
        const valueType = typeof value;

        if (value === null || valueType === 'undefined') {
            return null;
        }

        if (valueType === 'string' && typeof format === 'string') {
            return dayjs(value, format).isValid() ? dayjs(value, format).valueOf() : null;
        } else if (!(value instanceof dayjs)) {
            return dayjs(value).isValid() ? dayjs(value).valueOf() : null;
        }
        return null;
    },
    format: function (time, format) {
        return dayjs(time).format(format);
    },
    add: function (time, amount, unit) {
        return dayjs(time).add(amount, unit).valueOf();
    },
    diff: function (max, min, unit) {
        return dayjs(max).diff(dayjs(min), unit);
    },
    startOf: function (time, unit, weekday) {
        if (unit === 'isoWeek') {
            // Ensure that weekday has a valid format
            //const formattedWeekday

            const validatedWeekday = typeof weekday === 'number' && weekday > 0 && weekday < 7 ? weekday : 1;

            return dayjs(time).isoWeekday(validatedWeekday).startOf('day').valueOf();
        }

        return dayjs(time).startOf(unit).valueOf();
    },
    endOf: function (time, unit) {
        return dayjs(time).endOf(unit).valueOf();
    }
});
