import request, {RequestError} from '../utils/request';

import {createContext} from 'react';
import {message} from 'antd';
import pages from '../router/pages';
import useRequest from '../hooks/useRequest.js';

const PUBLIC_PATHS = pages.filter(page => page.public).map(page => page.path);

const UserContext = createContext(null);

export default UserContext;

export function Provider({children}) {
    const userData = useRequest(() => request('/user/info'), {
        silence: true,
        onError: e => {
            userData.mutate({
                id: null,
                displayName: '',
                name: '',
                roles: []
            });
            if (e instanceof RequestError && e.response.status === 401) {
                if (!PUBLIC_PATHS.includes(window.location.pathname)) {
                    window.location.href = '/';
                }
            } else {
                message.error(e.message);
            }
        }
    });

    return <UserContext.Provider value={userData}>{children}</UserContext.Provider>;
}
