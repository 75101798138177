import {Outlet, useMatches} from 'react-router-dom';
import {Suspense, useContext, useMemo} from 'react';

import {Button} from 'antd';
import Content from '../components/Content';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Layout from './Layout';
import {Link} from 'react-router-dom';
import Logo from '../components/Logo';
import Progress from '../components/Progress';
import UserContext from '../context/UserContext';
import styled from 'styled-components';

const StyledHeader = styled(Header)`
    width: 100%;
`;

const StyledContent = styled(Content)`
    padding: 20px;
    min-height: calc(
        100vh - ${props => props.theme.Layout.layoutHeaderHeight}px -
            ${props => props.theme.Layout.layoutFooterHeight}px
    ) !important;
`;

const LoginButtonWrapper = styled.div`
    position: absolute;
    right: 20px;
    top: 0;
`;

export default function SimpleLayout() {
    const {data: userData} = useContext(UserContext);

    const matches = useMatches();
    const {showLogin, contentStyle} = useMemo(() => matches[1]?.data.layoutProps ?? {}, [matches]);

    return (
        <Layout>
            <StyledHeader>
                <Logo />
                {showLogin === false ? null : (
                    <LoginButtonWrapper>
                        <Button>
                            {userData?.id != null ? <Link to="/">后台</Link> : <Link to="/login">登录</Link>}
                        </Button>
                    </LoginButtonWrapper>
                )}
            </StyledHeader>
            <Layout>
                <StyledContent style={contentStyle}>
                    <Suspense fallback={<Progress />}>
                        <Outlet />
                    </Suspense>
                </StyledContent>
            </Layout>
            <Footer />
        </Layout>
    );
}
