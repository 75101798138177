import {createContext, useCallback, useState} from 'react';

import {ROOM_ID} from '../configs/app.js';
import {THEME} from '../configs/enums.js';

const LOCAL_KEY = `theme-${ROOM_ID}`;

function checkTheme(theme) {
    return Object.values(THEME).includes(theme);
}

function getLocalTheme() {
    const localTheme = window.localStorage.getItem(LOCAL_KEY);
    if (checkTheme(localTheme)) {
        return localTheme;
    }
    return THEME.AUTO;
}

function setLocalTheme(theme) {
    if (checkTheme(theme)) {
        window.localStorage.setItem(LOCAL_KEY, theme);
        return theme;
    }
    window.localStorage.setItem(LOCAL_KEY, THEME.AUTO);
    return THEME.AUTO;
}

const ThemeContext = createContext(null);

export default ThemeContext;

export function Provider({children}) {
    const [theme, setTheme] = useState(getLocalTheme());

    const changeTheme = useCallback(theme => setTheme(setLocalTheme(theme)), []);

    return <ThemeContext.Provider value={[theme, changeTheme]}>{children}</ThemeContext.Provider>;
}
