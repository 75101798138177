import 'antd/dist/reset.css';
import 'nprogress/nprogress.css';
import './utils/date-adapter.js';

import {
    BarElement,
    Chart as ChartJS,
    Filler,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Tooltip
} from 'chart.js';

import App from './App.jsx';
// import {Buffer} from 'buffer';
import React from 'react';
import {createRoot} from 'react-dom/client';
import reportWebVitals from './reportWebVitals.js';

// window.Buffer = Buffer;

ChartJS.register(LinearScale, TimeScale, PointElement, LineElement, BarElement, Tooltip, Filler);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(process.env.NODE_ENV === 'development' ? console.info : undefined);
