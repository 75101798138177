import {BUILD_TIME, COPYRIGHT, LEGAL_INFO, VERSION} from '../configs/app.js';
import {Layout, Tooltip} from 'antd';

import {HeartTwoTone} from '@ant-design/icons';
import styled from 'styled-components';

const StyledFooter = styled(Layout.Footer)`
    color: ${props => props.theme.colorTextPlaceholder} !important;
    text-align: center;
    line-height: 1.5;
    height: ${props => props.theme.Layout.layoutFooterHeight}px;
`;

export default function Footer({className, style}) {
    return (
        <StyledFooter className={className} style={style}>
            <Tooltip title={`v${VERSION} @ ${new Date(BUILD_TIME).toLocaleString(undefined, {hour12: false})}`}>
                <span>
                    {COPYRIGHT} 晚兮 <HeartTwoTone twoToneColor="#eb2f96" /> 版权所有
                </span>
            </Tooltip>
            <br />
            <span>
                {LEGAL_INFO ? (
                    <>
                        &nbsp;
                        <a
                            href="https://beian.miit.gov.cn/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{color: 'currentColor'}}
                        >
                            {LEGAL_INFO}
                        </a>
                    </>
                ) : null}
            </span>
        </StyledFooter>
    );
}
