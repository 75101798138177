import {App} from 'antd';
import {useRequest as useARquest} from 'ahooks';

export default function useRequest(service, options, plugins) {
    const {message} = App.useApp();

    return useARquest(
        async (...params) => {
            try {
                return await service(...params);
            } catch (e) {
                if (!options.silence) {
                    message.error(e.message);
                }
                throw e;
            }
        },
        options,
        plugins
    );
}
