import {ThemeProvider} from 'styled-components';
import {theme} from 'antd';

const {useToken} = theme;

export default function Theme({children}) {
    const {token} = useToken();

    return <ThemeProvider theme={token}>{children}</ThemeProvider>;
}
