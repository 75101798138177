import {Layout} from 'antd';
import styled from 'styled-components';

const StyledHeader = styled(Layout.Header)`
    background-color: ${props => props.theme.colorBgBase};
    border-bottom: 1px solid ${props => props.theme.colorBorderSecondary};
    position: fixed;
    top: 0;
    z-index: 10;
    text-align: center;
`;

export default function Header({className, style, children}) {
    return (
        <StyledHeader className={className} style={style}>
            {children}
        </StyledHeader>
    );
}
