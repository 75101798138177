import {LOGO, ROOM_ID, TITLE} from '../configs/app.js';
import {useEffect, useState} from 'react';

import styled from 'styled-components';

const Img = styled.img`
    height: 50px;
`;

export default function Logo() {
    const [src, setSrc] = useState('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');

    useEffect(() => {
        (async () => {
            if (LOGO) {
                const {default: img} = await import(`../assets/${LOGO}`);
                setSrc(img);
            }
        })();
    }, []);

    return (
        <a href={`https://live.bilibili.com/${ROOM_ID}`} target="_blank" rel="noopener noreferrer">
            <Img src={src} alt={TITLE} />
        </a>
    );
}
