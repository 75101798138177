export const USER_ROLES = {
    ADMIN: 'admin',
    ANCHOR: 'anchor',
    STAFF: 'staff',
    EDITOR: 'editor',
    MAID: 'maid'
};

export const ENROLL_STATUS = {
    UNCONFIRMED: 0,
    CONFIRMED: 1,
    IGNORED: 2,
    SKIPPED: 3,
    SUCCESS: 4,
    FAILED: 5
};

export const ENROLL_TYPE = {
    AUTO: 0,
    MANUAL: 1
};

export const ENROLL_ORIENTATION = {
    LEFT: 'left',
    RIGHT: 'right'
};

export const GIFT_TYPE = {
    GOLD: 'gold',
    SILVER: 'silver'
};

export const GIFT_STATUS = {
    CURRENT: 0b0001,
    AVAILABLE: 0b0010,
    EXPIRED: 0b0100
};

export const GUARD_LEVEL = {
    NONE: 0,
    GOVERNOR: 1,
    ADMIRAL: 2,
    CAPTAIN: 3
};

export const GUARD_STATUS = {
    VALID: 0,
    EXPIRED: 1
};

export const DANMAKU_TYPE = {
    NORMAL: 0, // 正常弹幕
    STORM: 1, // 节奏风暴
    LOTTERY: 2 // 天选
};

export const SEX = {
    UNKNOWN: '保密',
    MALE: '男',
    FEMALE: '女'
};

export const RECORD_STATUS = {
    FINISHED: 0,
    UPLOADING: 1,
    ERROR: 2,
    RECORDING: 3,
    TRANSCODING: 4
};

export const ACCOUNT_CHANNEL = {
    GENSHIN: 0b01,
    STARRAIL: 0b10
};

export const ACCOUNT_SERVER = {
    OFFICIAL_CHINA: 100,
    BILIBILI: 101,
    OFFICIAL_OVERSEAS: 102
};

export const THEME = {
    AUTO: 'auto',
    LIGHT: 'light',
    DARK: 'dark'
};
