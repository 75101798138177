import {Link, useNavigate} from 'react-router-dom';
import {MID, ROOM_ID} from '../configs/app.js';
import {useContext, useMemo} from 'react';

import UserContext from '../context/UserContext';
import {UserOutlined} from '@ant-design/icons';
import request from '../utils/request';
import useRequest from './useRequest.js';

export default function useUserMenu(hideUsername) {
    const navigate = useNavigate();

    const {data: userData} = useContext(UserContext);

    const {run: logout} = useRequest(() => request('/user/logout'), {
        manual: true,
        onSuccess: () => navigate('/login')
    });

    const userMenu = useMemo(
        () => [
            {
                key: 'user-space',
                label: (
                    <a href={`https://space.bilibili.com/${MID}`} target="_blank" rel="noopener noreferrer">
                        我的空间
                    </a>
                )
            },
            {
                key: 'user-live-room',
                label: (
                    <a href={`https://live.bilibili.com/${ROOM_ID}`} target="_blank" rel="noopener noreferrer">
                        我的直播间
                    </a>
                )
            },
            {
                key: 'user-profile',
                label: <Link to="/profile">账号信息</Link>
            },
            {
                type: 'divider'
            },
            {
                key: 'user-logout',
                label: <div onClick={logout}>退出登录</div>
            }
        ],
        [logout]
    );
    const userMenuItem = useMemo(() => {
        const username = userData?.displayName || userData?.name || '神秘人';
        return {
            key: 'user',
            label: hideUsername ? '' : username,
            icon: <UserOutlined />,
            selectable: false,
            children: hideUsername ? [{type: 'group', label: username, children: userMenu}] : userMenu
        };
    }, [hideUsername, userData, userMenu]);

    return {item: userData?.id != null ? userMenuItem : null, selectedKeys: []};
}
