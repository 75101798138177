import HttpStatus from 'http-status';

export const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/api' : '/api';

export class RequestError extends Error {
    response;

    constructor(message, response) {
        super(message);
        this.response = response;
    }
}

export default async function request(key, params) {
    const headers = {
        'Content-Type': 'application/json'
    };
    const res = await fetch(`${API_URL}${key}?t=${+new Date()}`, {
        method: 'POST',
        mode: 'cors',
        headers,
        redirect: 'follow',
        body: 'string' === typeof params ? params : JSON.stringify(params ?? {})
    });

    if (res.status === HttpStatus.UNAUTHORIZED) {
        throw new RequestError('木有权限 QAQ', res);
    }

    const response = await res.json();
    if (response.success) {
        return response.result;
    } else {
        throw new RequestError(response.message, res);
    }
}
