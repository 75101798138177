import {ROLE_MENU} from '../configs/roles.js';
import menus from '../router/menu.jsx';

export function getPathsByRoles(roles) {
    const ids = [
        ...new Set(
            roles
                .map(role => ROLE_MENU[role])
                .filter(p => p != null)
                .flat()
        )
    ];

    return menus.filter(menu => ids.includes(menu.id));
}
