import NProgress from 'nprogress';
import {useEffect} from 'react';

export default function Progress() {
    useEffect(() => {
        NProgress.start();
        return () => NProgress.done();
    }, []);
    return null;
}
