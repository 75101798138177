import {Layout as AntdLayout} from 'antd';
import {useMatches} from 'react-router-dom';
import {useMemo} from 'react';

export default function Layout({className, style, children}) {
    const matches = useMatches();
    const pageClassName = useMemo(() => (matches[1]?.id ? `${matches[1].id}-page` : ''), [matches]);

    return (
        <AntdLayout className={`${className} ${pageClassName}`} style={style}>
            {children}
        </AntdLayout>
    );
}
