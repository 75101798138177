import AutoTheme from './AutoTheme.jsx';
import StyledComponentTheme from './StyledComponentTheme.jsx';
import {Provider as ThemeProvider} from '../context/ThemeContext.jsx';

export default function Theme({children}) {
    return (
        <ThemeProvider>
            <AutoTheme>
                <StyledComponentTheme>{children}</StyledComponentTheme>
            </AutoTheme>
        </ThemeProvider>
    );
}
