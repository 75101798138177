import {App as AntdApp, ConfigProvider} from 'antd';

import {DndProvider} from 'react-dnd';
import GlobalStyle from './style/GlobalStyle.js';
import {HTML5Backend} from 'react-dnd-html5-backend';
import ProgressStyle from './style/ProgressStyle.js';
import Router from './router/Router.jsx';
import {StyleSheetManager} from 'styled-components';
import Theme from './style/Theme.jsx';
import {Provider as UserProvider} from './context/UserContext.jsx';
import zhCN from 'antd/locale/zh_CN.js';

export default function App() {
    return (
        <StyleSheetManager enableVendorPrefixes>
            <ConfigProvider locale={zhCN}>
                <Theme>
                    <AntdApp>
                        <GlobalStyle />
                        <ProgressStyle />
                        <DndProvider backend={HTML5Backend}>
                            <UserProvider>
                                <Router />
                            </UserProvider>
                        </DndProvider>
                    </AntdApp>
                </Theme>
            </ConfigProvider>
        </StyleSheetManager>
    );
}
