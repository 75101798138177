import {USER_ROLES} from './enums.js';

export const ROLE_MENU = {
    [USER_ROLES.ADMIN]: [
        'statistic',
        'danmaku-live',
        'gift-live',
        'guard-history',
        'guard-management',
        'fan-management',
        'video-list',
        'danmaku-enroll',
        'gift-gallery',
        'account-management',
        'user-management',
        'system-admin'
    ],
    [USER_ROLES.ANCHOR]: [
        'statistic',
        'danmaku-live',
        'gift-live',
        'guard-history',
        'guard-management',
        'fan-management',
        'video-list',
        'danmaku-enroll',
        'gift-gallery',
        'account-management'
    ],
    [USER_ROLES.STAFF]: [
        'statistic',
        'danmaku-live',
        'gift-live',
        'guard-history',
        'guard-management',
        'fan-management',
        'video-list',
        'danmaku-enroll',
        'gift-gallery',
        'account-management'
    ],
    [USER_ROLES.EDITOR]: ['video-list'],
    [USER_ROLES.MAID]: ['account-management']
};
