import {Link, useLocation} from 'react-router-dom';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {Menu} from 'antd';
import UserContext from '../context/UserContext';
import {getPathsByRoles} from '../utils/menu';
import styled from 'styled-components';

const StyledMenu = styled(Menu)`
    color: inherit;
    background-color: inherit;
`;

export default function AppMenu({className, style}) {
    const location = useLocation();
    const {data: userData} = useContext(UserContext);

    const [current, setCurrent] = useState(location.pathname);
    useEffect(() => {
        setCurrent(location.pathname);
    }, [location.pathname]);

    const click = useCallback(e => {
        setCurrent(e.key);
    }, []);

    const menuList = useMemo(() => getPathsByRoles(userData?.roles ?? []), [userData]);
    const menuItems = useMemo(
        () =>
            menuList.map(menu => ({
                label: <Link to={menu.path}>{menu.label}</Link>,
                key: menu.path,
                icon: menu.icon
            })),
        [menuList]
    );

    return (
        <StyledMenu
            mode="inline"
            items={menuItems}
            selectedKeys={[current]}
            onClick={click}
            className={className}
            style={style}
        />
    );
}
