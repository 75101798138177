import {ConfigProvider, theme} from 'antd';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {THEME} from '../configs/enums.js';
import ThemeContext from '../context/ThemeContext.jsx';

const {defaultAlgorithm, darkAlgorithm, defaultSeed} = theme;

const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');

export default function AutoTheme({children}) {
    const [theme] = useContext(ThemeContext);

    const [dark, setDark] = useState(theme === THEME.AUTO ? matchMedia.matches : theme === THEME.DARK);
    useEffect(() => {
        setDark(theme === THEME.AUTO ? matchMedia.matches : theme === THEME.DARK);
    }, [theme]);

    const onDarkChange = useCallback(
        e => {
            if (theme === THEME.AUTO) {
                setDark(e.matches);
            }
        },
        [theme]
    );

    useEffect(() => {
        matchMedia.addEventListener('change', onDarkChange);
        return () => {
            matchMedia.removeEventListener('change', onDarkChange);
        };
    }, [onDarkChange]);

    const token = useMemo(() => (dark ? darkAlgorithm : defaultAlgorithm)(defaultSeed), [dark]);

    return (
        <ConfigProvider
            theme={{
                token,
                components: {
                    Layout: {
                        colorBgHeader: token.colorBgContainer,
                        colorBgBody: token.colorBgBase,
                        // TODO: not affected, set manually
                        // track with https://github.com/ant-design/ant-design/issues/38975
                        layoutHeaderHeight: token.controlHeight * 2,
                        layoutFooterHeight: token.controlHeight * 2.8125,
                        menuSiderWidth: 200
                    }
                }
            }}
        >
            {children}
        </ConfigProvider>
    );
}
