import {EXCLUDE_PAGES} from '../configs/app.js';

const pages = [
    {
        id: 'index',
        path: '/',
        public: true,
        component: () => import('../pages/IndexPage')
    },
    {
        id: 'login',
        path: '/login',
        public: true,
        component: () => import('../pages/LoginPage')
    },
    {
        id: 'record-list',
        path: '/records',
        public: true,
        component: () => import('../pages/RecordListPage')
    },
    {
        id: 'statistic',
        path: '/statistic',
        component: () => import('../pages/StatisticPage')
    },
    {
        id: 'user-profile',
        path: '/profile',
        component: () => import('../pages/UserProfilePage')
    },
    {
        id: 'user-management',
        path: '/system/user',
        component: () => import('../pages/SystemUserPage')
    },
    {
        id: 'system-admin',
        path: '/system/admin',
        component: () => import('../pages/SystemAdminPage')
    },
    {
        id: 'danmaku-live',
        path: '/danmaku/live',
        component: () => import('../pages/DanmakuLivePage')
    },
    {
        id: 'danmaku-enroll',
        path: '/danmaku/enroll',
        component: () => import('../pages/DanmakuEnrollPage')
    },
    {
        id: 'guard-management',
        path: '/guards/management',
        component: () => import('../pages/GuardManagementPage')
    },
    {
        id: 'guard-history',
        path: '/guards/history',
        component: () => import('../pages/GuardHistoryPage')
    },
    {
        id: 'fan-management',
        path: '/fans/management',
        component: () => import('../pages/FansManagementPage')
    },
    {
        id: 'account-management',
        path: '/account/management',
        component: () => import('../pages/AccountManagementPage')
    },
    {
        id: 'video-list',
        path: '/video/list',
        component: () => import('../pages/VideoListPage')
    },
    {
        id: 'gift-live',
        path: '/gift/live',
        component: () => import('../pages/GiftLivePage')
    },
    {
        id: 'gift-statistic',
        path: '/gift/statistic',
        component: () => import('../pages/GiftStatisticPage')
    },
    {
        id: 'gift-gallery',
        path: '/gift/gallery',
        component: () => import('../pages/GiftGalleryPage')
    }
].filter(page => !EXCLUDE_PAGES.includes(page.id));

export default pages;
